.footer {
  background: #bc2b3b;
  color: #fff;
  padding: 2em;
  font-family: Arial, Helvetica, sans-serif;
}
.footer h4 {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff !important;
}
.footer h5 {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff !important;
}
.footTop {
  display: flex;
  justify-content: space-between;
}
.firstsec {
  display: flex;
  justify-content: space-between;
}
.firstsec div {
  text-align: start;
  padding: 2em;
}
.firstsec div img {
  max-width: 6em;
}
.firstsec div h4 {
  margin: 0;
  margin-bottom: 1em;
  text-align: start;
}
.firstsec div a {
  text-decoration: none;
  color: #fff;
}
.bottext h5 {
  margin: 0;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social div {
  margin: 2em;
}
.social div img {
  margin: 1em;
}

@media (max-width: 1000px) {
  .firstsec {
    justify-content: space-around;
  }
  .footTop {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .firstsec {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .firstsec div h4 {
    text-align: center;
  }
  .social {
    flex-direction: column;
  }
}
